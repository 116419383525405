import React, {useEffect} from 'react';
import {BottomNavigation, BottomNavigationAction, Container, Grid} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {EmojiEventsOutlined, PeopleAltOutlined} from "@mui/icons-material";
import {Link, Route, Routes} from "react-router-dom";
import Rank from "./Rank";
import Groups from "./Groups";
import Prediction from "./Prediction";
import {getCompetitionRounds} from "../redux/overviewSlice";
import {getCompetitionId} from "../Util";
import {currentUserToken} from "../redux/authSlice";
import {useDispatch, useSelector} from "react-redux";

function getPathIndex() : number{
    if (window.location){
        if (window.location.pathname.endsWith("/rank")){
            return 1;
        } else if (window.location.pathname.endsWith("/groups")){
            return 2;
        }
    }
    return 0;
}

function LoggedIdApp() {
    const dispatch = useDispatch<any>();
    const token = useSelector(currentUserToken);
    const [value, setValue] = React.useState(getPathIndex());
    const [competitionId, setCompetitionId] = React.useState(getCompetitionId());
    useEffect(() => {
        setValue(getPathIndex());
        setCompetitionId(getCompetitionId());
    },
        // eslint-disable-next-line
        [window.location.pathname]); // react says this is not necasarry, but toggling between competitions fails without the window.location.pathname dependency


    useEffect(() => {
        if (competitionId > 0){
            dispatch(getCompetitionRounds([token, competitionId]));
        }
    }, [dispatch, token, competitionId]);

    return (
          <div>
              <div style={{position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100, top: 0, left: 0}}>
                  <Container maxWidth="md">
                      <Grid container>
                          <Grid item md={1} sm={2} xs={false}/>
                          <Grid item xs={1}/>
                          <Grid item md={10} sm={9} xs={11}>
                              <BottomNavigation
                                  value={value}
                                  onChange={(event, newValue) => {
                                      setValue(newValue);
                                  }}
                                  showLabels
                                  className={""}

                              >
                                  <BottomNavigationAction component={Link} to={`${competitionId}/prediction`} label="Voorspelling" icon={<HelpOutlineOutlinedIcon />} />
                                  <BottomNavigationAction component={Link} to={`${competitionId}/rank`}  label="Ranglijst" icon={<EmojiEventsOutlined />} />
                                  <BottomNavigationAction component={Link} to={`${competitionId}/groups`} label="Groepen" icon={<PeopleAltOutlined />} />
                              </BottomNavigation>
                          </Grid>
                      </Grid>
                  </Container>
              </div>


              <div>
                  <Routes>
                      <Route path="/:competitionId/prediction" element={<Prediction />}/>
                      <Route path="/:competitionId/rank" element={<Rank />}/>
                      <Route path="/:competitionId/groups" element={<Groups />}/>
                  </Routes>
              </div>
          </div>
  );
}

export default LoggedIdApp;
