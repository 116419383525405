import React, {CSSProperties, useEffect, useState} from 'react';
import {
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
    Alert
} from "@mui/material";
import {createGroup, fetchUserGroups, groupFetchState, groupsOfUser, joinGroup, leaveGroup} from "../redux/groupSlice";
import {useDispatch, useSelector} from "react-redux";
import {Group} from "../models/group";
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import {useParams} from "react-router-dom";
import {currentUserToken} from "../redux/authSlice";

function Groups(){
    const dispatch = useDispatch<any>();
    const selectedCompetition = useParams().competitionId;
    const token = useSelector(currentUserToken);
    const [joincode, setJoincode] = useState('');
    const [groupName, setGroupName] = useState('');
    const [groupToLeave, setGroupToLeave] = useState<Group | undefined>(undefined);

    const groupsForUser = useSelector(groupsOfUser);
    const connectionState = useSelector(groupFetchState);

    const loader  = connectionState.pending ? <CircularProgress/> : null;
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    useEffect(() => {
        dispatch(fetchUserGroups([token, selectedCompetition]));
    }, [dispatch, token, selectedCompetition]);

    let connectionStateStyle: CSSProperties = {position: 'fixed', width: '100%', top: 0, left: 0, paddingTop: '3.5em', paddingBottom: "1em"};
    if (loader !== null){
        connectionStateStyle.textAlign = 'right';
    }
    if (alert !== null){
        connectionStateStyle.backgroundColor = 'white';
        connectionStateStyle.zIndex = 99;
    }

    return (
        <div>
            <div style={connectionStateStyle}>
                <Container maxWidth="sm">
                    {loader}
                    {alert}
                </Container>
            </div>
            <Container style={{paddingTop: '3em'}} maxWidth="sm">
                <div className={'hidden'}>
                    {alert}
                </div>

                <h3 style={{marginBottom: '0.5em'}}>Aansluiten</h3>
                <div>
                    Sluit aan bij een bestaande groep door de groepscode van 15 karakters in te voeren.
                    <TextField
                        disabled={connectionState.pending}
                        value={joincode}
                        onChange={(val: any) => {
                            let valueFromTextField: string = val.target.value;
                            setJoincode(valueFromTextField);
                            if(valueFromTextField.length === 15){
                                dispatch(joinGroup([token, valueFromTextField, selectedCompetition]));
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="joincode"
                        label="Groepscode"
                        name="joincode"
                    />
                </div>

                <h3 style={{marginBottom: '0.5em'}}>Nieuwe groep</h3>
                <div>
                    Maak een nieuwe groep door een groepsnaam te kiezen.
                    <TextField
                        value={groupName}
                        disabled={connectionState.pending}
                        helperText={groupName.length > 25 ? "Een groepsnaam mag niet langer dan 25 tekens zijn" : ""}
                        error={groupName.length > 25}
                        onChange={(val: any) => setGroupName(val.target.value)}
                        onKeyUp={(val) => {
                            if (val.key === 'Enter' && !connectionState.pending && groupName.length > 0 && groupName.length < 26){
                                dispatch(createGroup([token, groupName, selectedCompetition]));
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="groupname"
                        label="Groepsnaam"
                        name="groupname"
                    />
                    <Button
                        disabled={connectionState.pending || groupName.length === 0 || groupName.length > 25}
                        onClick={() => {
                            dispatch(createGroup([token, groupName, selectedCompetition]));
                        }}
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Maak groep
                    </Button>
                </div>

                <div>
                    <h3 style={{marginBottom: '0.5em', marginTop: '1.5em'}}>Mijn groepen</h3>
                    <Alert icon={false} style={{marginBottom: '1em'}} severity={"info"}>Via <strong>Ranglijst</strong> (bovenaan pagina) en <strong>Kies groep</strong> kun je de ranglijst van je groep zien.</Alert>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Naam</TableCell>
                                    <TableCell align="left">Code</TableCell>
                                    <TableCell align="left">Verlaten?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupsForUser.map((group: Group) => getGroupRow(group, connectionState.pending, () => setGroupToLeave(group)))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog
                        open={groupToLeave !== undefined}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Weet je zeker dat je de groep '{groupToLeave?.name}' wil verlaten?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setGroupToLeave(undefined)} color="primary">
                                Nee
                            </Button>
                            <Button onClick={() => {setGroupToLeave(undefined);dispatch(leaveGroup([token, groupToLeave?.id, selectedCompetition, groupToLeave?.name]));}} color="secondary">
                                Ja
                            </Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </Container>
        </div>

    );
}

function getGroupRow(group: Group, disabled: boolean, onLeaveGroup: () => void) {

    const leaveButton = group.containsAllPlayers ? null : <Button
        disabled={disabled}
        onClick={onLeaveGroup}
        color="secondary"
        fullWidth
        variant={"contained"}
        startIcon={<ExitToAppSharpIcon/>}
    />

    const code = group.containsAllPlayers ? "" : group.code;

    return <TableRow key={group.id}>
        <TableCell align="left"><span>{group.name}</span><br /><span>{prettifyDate(group.startOfGroup)}</span></TableCell>
        <TableCell align="left">{code}</TableCell>
        <TableCell align="left">
            {leaveButton}
        </TableCell>
    </TableRow>;
}


function prettifyDate(dateAsString: string){
    if (!dateAsString){
        return dateAsString;
    }

    let date: Date = new Date(dateAsString);
    // let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    // let month = ['januari', 'februari','maart','april','mei','juni','juli','augustus','september','oktober','november','december'][date.getMonth()];
    // return date.getDate() + " " + month + " " + date.getFullYear() + " " + date.getHours() + ":" + minutes;
    let month = ['jan.', 'feb.','maart','april','mei','juni','juli','aug.','sept.','okt.','nov.','dec.'][date.getMonth()];
    return "Punten vanaf " + date.getDate() + " " + month + " " + date.getFullYear();
}

export default Groups;

