import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getBaseUrl} from "../Util";
import {FetchState} from "../models/fetchState";

export const requestPasswordResetToken = createAsyncThunk<any, any[], any>(
    'requestPasswordReset/requestPasswordResetToken',
    async usernameAndRecaptcha => {
        const response = await fetch(`${getBaseUrl()}/api/auth/sendResetPassLink`,{
            method: "POST",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `username=${usernameAndRecaptcha[0]}&recaptchaResponse=${usernameAndRecaptcha[1]}`
        }).then(resp => resp.status)

        return response
    }
);

const initialState: FetchState = {
    pending: false, severity: "info", message: ""
}


export const requestPasswordResetSlice = createSlice({
    name: 'requestPasswordReset',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(requestPasswordResetToken.pending, (state: FetchState, action: any) => {
                state.message = "";
                state.pending = true;
            })
            .addCase(requestPasswordResetToken.fulfilled, (state: FetchState, action: any) => {
                state.pending = false;
                if (action.payload === 200) {
                    state.severity = "success";
                    state.message = "Er is een e-mail met een wachtwoordherstellink gestuurd.";
                } else if (action.payload === 468){
                    state.message = "Het lijkt erop dat je een robot bent. Robots mogen geen wachtwoorden opnieuw instellen."
                    state.severity = "warning";
                } else if (action.payload === 469){
                    state.message = "Er is niemand met de gebruikersnaam '" + action.meta.arg[0] + "'";
                    state.severity = "warning";
                }
                else {
                    state.severity = 'error';
                    state.message = "Er ging iets fout: " + action.payload;
                }
            })
            .addCase(requestPasswordResetToken.rejected, (state: FetchState, action: any) => {
                state.pending = false;
                state.severity = 'error';
                state.message = "Kan geen verbinding maken.";
            })

    }
});

export const requestPasswordResetFetchState = (state: { requestPasswordReset: FetchState; }) => state.requestPasswordReset;

