import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getBaseUrl} from "../Util";
import {FetchState} from "../models/fetchState";
import {RankState} from "../models/rankState";
import {fetchUserGroups} from "./groupSlice";

export const fetchRankForGroupAndRound = createAsyncThunk<any, any[], any>(
    'groups/getRankForGroupRoundAndPage',
    async tokenGroupRoundAndPage => {
        const response = await fetch(`${getBaseUrl()}/api/game/rank/getRankForGroupRoundAndPage?groupId=${tokenGroupRoundAndPage[1]}&roundId=${tokenGroupRoundAndPage[2]}&page=${tokenGroupRoundAndPage[3]}`,{
            method: "GET",
            headers: {'Authorization':'Bearer ' + tokenGroupRoundAndPage[0]},
            credentials: "include"
        }).then(resp => resp.json())

        return response
    }
);

const initialState: RankState = {
    fetchState: {pending: false, severity: "info", message: ""},
    selectedGroup: '',
    selectedPage: -1,
    userScore: null,
    selectedRound: '',
    rowCount: 100,
    itemsPerPage: 75,
    rows: []
}

export const rankSlice = createSlice({
    name: 'rank',
    initialState: initialState,
    reducers: {
        selectRoundForRank: (state: RankState, selectedRound: PayloadAction<string>) => {
            state.selectedRound = selectedRound.payload;
        },
        selectGroup: (state: RankState, selectedGroup: PayloadAction<string>) => {
            state.selectedGroup = selectedGroup.payload;
        },
        selectPage: (state: RankState, selectedPage: PayloadAction<number>) => {
            state.selectedPage = selectedPage.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRankForGroupAndRound.pending, (state: RankState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(fetchRankForGroupAndRound.fulfilled, (state: RankState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload.status) {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload.status;
                } else {
                    state.rowCount = action.payload.rowCount;
                    state.itemsPerPage = action.payload.itemsPerPage;
                    state.selectedPage = action.payload.selectedPage;
                    state.userScore = action.payload.userScore;
                    let startIndex: number = (state.selectedPage) * state.itemsPerPage + 1;
                    state.rows = action.payload.rows; //sort((rnd1: IdWithName, rnd2: IdWithName) => rnd1.name.localeCompare(rnd2.name));
                    state.rows.forEach((row, idx) => {
                        row.id = row.name;
                        if (idx > 0 && state.rows[idx - 1].points === row.points){
                            row.index = state.rows[idx - 1].index;
                        } else {
                            row.index = startIndex + idx;
                        }

                    });
                    state.fetchState.message = "";
                }
            })
            .addCase(fetchRankForGroupAndRound.rejected, (state: RankState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
            .addCase(fetchUserGroups.pending, (state: RankState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(fetchUserGroups.fulfilled, (state: RankState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload.status) {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload.status;
                }
            })
            .addCase(fetchUserGroups.rejected, (state: RankState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })

    }
});

export const {selectRoundForRank, selectGroup, selectPage} = rankSlice.actions;
export const selectedRoundForRankSelector = (state: { rank: { selectedRound: string; }; }) => state.rank.selectedRound;
export const selectedGroupSelector = (state: { rank: { selectedGroup: string; }; }) => state.rank.selectedGroup;
export const selectedPageSelector = (state: { rank: { selectedPage: number; }; }) => state.rank.selectedPage;
export const userScoreSelector = (state: { rank: { userScore: number; }; }) => state.rank.userScore;
export const rankFetchState = (state: { rank: { fetchState: FetchState; }; }) => state.rank.fetchState;
export const rank = (state: { rank: RankState; }) => state.rank;
