import React, {useRef} from 'react';
import {useSelector} from "react-redux";
import {userCompetitionsSelector} from "../redux/overviewSlice";
import {currentUser} from "../redux/authSlice";
import {getWPBaseUrl, getWPSearch} from "../Util";
import {Alert} from "@mui/material";
import {useLocation} from "react-router-dom";

function News(){

    const [iFrameHeight, setIFrameheight] = React.useState("15px");
    const [hasCrossOriginProblem, setHasCrossOriginProblem] = React.useState(false);
    const ref = useRef<HTMLIFrameElement>(null);
    let userCompetitions = useSelector(userCompetitionsSelector);
    const crrntUser = useSelector(currentUser);

    let filter;
    const location = useLocation();
    let search = getWPSearch(location);

    if (search){
        filter = search;
    } else {
        const tags = ["everyone"];

        if (crrntUser){
            tags.push("user_" + crrntUser.username);
            crrntUser.groupIdsOfUser.forEach(groupId => tags.push(`group_${groupId}`));
            userCompetitions.filter(usercomp => usercomp.userParticipates).forEach(usercomp => tags.push(usercomp.competitionName));
        }else {
            tags.push("korfbal-league");
            tags.push("eredivisie");
        }

        filter = "?tag=" + tags.map(tag => tag.replace(" ", "-").toLowerCase()).join(',');
    }

    const style = {
        border: 'none',
        width: '100%',
        height: iFrameHeight,
        overflow: 'visible'
    };
    const src = `${getWPBaseUrl()}${filter}`;

    return (hasCrossOriginProblem ? <Alert severity="error">Nieuws laden mislukt</Alert> :
                <iframe
                ref={ref}
                style={style}
                onLoad={() => {
                    try {
                        const body = ref.current?.contentWindow?.document.body;
                        const neededHeight = Math.max(body?.clientHeight || 0, body?.offsetHeight || 0, body?.scrollHeight || 0);
                        setIFrameheight(neededHeight + "px");
                    } catch (e) {
                        console.log(e);
                        setHasCrossOriginProblem(true);
                    }
                }
                }
                scrolling="no"
                height={iFrameHeight}
                title="news"
                src={src}
                frameBorder="0"
            />
    );
}

export default News;
