import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {currentUser} from "../redux/authSlice";
import clsx from 'clsx';
import {fetchUserGroups, groupsOfUser} from "../redux/groupSlice";
import {
    Button,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Alert
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {IdWithName} from "../models/idWithName";
import {
    fetchRankForGroupAndRound,
    rank,
    rankFetchState,
    selectedGroupSelector,
    selectedPageSelector,
    selectedRoundForRankSelector,
    selectGroup,
    selectPage,
    selectRoundForRank,
    userScoreSelector
} from "../redux/rankSlice";
import {DataGrid, GridCallbackDetails, GridColumns} from '@mui/x-data-grid';
import {useWindowSize} from "../Util";
import {Group} from "../models/group";
import {roundsForCompetition} from "../redux/overviewSlice";
import {smallScreenBound} from "../Constants";
import {RankRow} from "../models/rankState";
import {GridCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {useParams} from "react-router-dom";

function Rank(){
    const dispatch = useDispatch<any>();
    const connectionState = useSelector(rankFetchState);
    const selectedCompetition = useParams().competitionId;
    const rankState = useSelector(rank);
    const size = useWindowSize();
    const crrntUser = useSelector(currentUser);

    const token = crrntUser?.token;
    const currentUserName = crrntUser?.username;

    const selectedPage = useSelector(selectedPageSelector);
    const selectedGroup = useSelector(selectedGroupSelector);
    const selectedRound = useSelector(selectedRoundForRankSelector);
    const userScore = useSelector(userScoreSelector);

    const hideFooter = rankState.rows.length < rankState.itemsPerPage && selectedPage === 0;

    const showReloadAndGroupStart = !connectionState.pending  && selectedRound !== '' && selectedGroup !== '';
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    const paddingTop = alert === null ? "11em" : "14em";

    const groupsForUser = useSelector(groupsOfUser);
    const roundsForComp = useSelector(roundsForCompetition);

    const headerHeight = 56;
    const rowHeight = 52;
    let containerHeight = headerHeight + rankState.rows.length * rowHeight + 48;
    if (!hideFooter){
        containerHeight += rowHeight;
    }

    let rankContainerStyle = size.width < smallScreenBound ? {paddingTop: paddingTop, paddingLeft: 0, paddingRight: 0} : {paddingTop: paddingTop};

    const ref = useRef<HTMLHeadingElement>(null);
    const containerWith = ref.current ? ref.current.offsetWidth - 2 : 0;
    let positionSize = Math.min(80, containerWith / 4);
    let positionName = positionSize < 80 ? "#" : "Positie";

    let pointSize = Math.min(80, containerWith / 4);
    let pointName = pointSize < 80 ? "Pnt." : "Punten";

    let nameSize = containerWith - positionSize - pointSize;

    const currentUserIndex = rankState.rows.findIndex(rankRow => rankRow.name === currentUserName);

    const func: (params: GridCellParams) => string = (params: GridCellParams) =>
        clsx({
            'current-user-score-row': params.row.name === currentUserName
        })

    const columnDef: GridColumns<RankRow> = [
        {field: 'index', headerName: positionName, width: positionSize, cellClassName: func},
        {field: 'points', headerName: pointName, width: pointSize, cellClassName: func},
        {field: 'name', headerName: 'Naam', width: nameSize, cellClassName: func}
    ];

    let rankGrid = selectedRound !== '' && selectedGroup !== '' && selectedPage >= 0 ?
        <DataGrid
            page={selectedPage}
            onPageChange={(page: number, _: GridCallbackDetails<any>) => {
            if (page !== selectedPage){
                dispatch(fetchRankForGroupAndRound([token, selectedGroup, selectedRound, page]));
            }
            dispatch(selectPage(page));
        }} hideFooter={hideFooter} loading={connectionState.pending} rows={rankState.rows} columns={columnDef} rowCount={rankState.rowCount} rowsPerPageOptions={[]} pageSize={rankState.itemsPerPage} paginationMode="server"/> : null;

    useEffect(() => {
        dispatch(fetchUserGroups([token, selectedCompetition]));
    }, [dispatch, token, selectedCompetition]);

    return (
        <div>
            <div style={{position: 'fixed', width: '100%', zIndex: 99, backgroundColor: "white", top: 0, left: 0, paddingTop: '4em', paddingBottom: "1em"}}>
                <Container maxWidth="md">
                    <Grid container>
                        <Grid item md={3} sm={4} xs={false}/>
                        <Grid item sm={4} xs={6} style={{paddingRight: 10}}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel id="groupSelectLabel">Kies groep</InputLabel>
                                <Select
                                    labelId="groupSelectLabel"
                                    id="GroupSelect"
                                    value={selectedGroup}

                                    onChange={(event: any, child) => {
                                        let selectedGroupId = event.target.value;
                                        dispatch(selectGroup(selectedGroupId));

                                        if (selectedRound !== ''){
                                            dispatch(selectPage(0));
                                            dispatch(fetchRankForGroupAndRound([token, selectedGroupId, selectedRound, 0]));
                                        }

                                    }}
                                    label="Groep"
                                >
                                    {groupsForUser.map((group: Group) => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={4} xs={6} style={{paddingLeft: 10}}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel id="roundSelectLabel">Ronde</InputLabel>
                                <Select
                                    labelId="roundSelectLabel"
                                    id="RoundSelect"
                                    value={selectedRound}

                                    onChange={(event: any, child) => {
                                        let selectedRoundId = event.target.value;
                                        dispatch(selectRoundForRank(selectedRoundId));

                                        if (selectedGroup !== ''){
                                            dispatch(selectPage(0));
                                            dispatch(fetchRankForGroupAndRound([token, selectedGroup, selectedRoundId, 0]));
                                        }
                                    }}
                                    label="Ronde"
                                >
                                    <MenuItem value={-1}>Totaal klassement</MenuItem>)
                                    {roundsForComp.map((idWithName: IdWithName) => <MenuItem key={idWithName.id} value={idWithName.id}>{idWithName.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container className={showReloadAndGroupStart ? "" : "hidden"}>
                        <Grid item md={3} sm={4} xs={false}/>
                        <Grid item md={4} sm={4} xs={6} style={{paddingLeft: '1em', paddingTop: "0.5em"}}>
                            {currentUserIndex < 0
                            ?
                                <Button variant="outlined" color="primary" onClick={() => dispatch(fetchRankForGroupAndRound([token, selectedGroup, selectedRound, -1]))}>
                                    Punten: {userScore === null ? "-" : userScore}
                                </Button>
                            :
                            <Typography variant="subtitle1" color="inherit">
                                <span>Punten: {userScore === null ? "-" : userScore}</span>
                            </Typography>
                            }
                        </Grid>
                        <Grid item md={4} sm={3} xs={5} style={{paddingTop: "0.5em", textAlign: "right"}}>
                            {selectedPage !== 0
                                ?
                                <Button variant="outlined" color="primary" onClick={() => dispatch(fetchRankForGroupAndRound([token, selectedGroup, selectedRound, 0]))}>
                                    Naar nr. 1
                                </Button>
                                :
                                null
                            }
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => {dispatch(fetchRankForGroupAndRound([token, selectedGroup, selectedRound, selectedPage]));}} color="primary" aria-label="Herlaad ronde">
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item md={3} sm={4} xs={false}/>
                        <Grid item md={9} sm={8} xs={12}>
                            {alert}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth="md" style={rankContainerStyle}>
                <div ref={ref} style={{height: containerHeight, zIndex: 98}}>
                    {rankGrid}
                </div>
            </Container>
        </div>
    );
}

export default Rank;

