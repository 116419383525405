import React, {CSSProperties, useState} from 'react';
import {Box, Button, CircularProgress, Grid, List, ListItem, ListItemText, TextField, Alert, AlertTitle} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch, useSelector} from "react-redux";
import {pendingAnswers, pendingQuestions, postAnswer, postResultAdmin} from "../redux/competitionSlice";
import {currentUser} from "../redux/authSlice";
import {Answer, Question} from "../models/competition";

function PredictionQuestion(question: Question){
    const dispatch = useDispatch<any>();
    const crrntUser = useSelector(currentUser);

    const pending_questions = useSelector(pendingQuestions);
    const pending_answers = useSelector(pendingAnswers);

    const [homeGoals, setHomegoals] = useState('');
    const [awayGoals, setAwaygoals] = useState('');

    let resultRow = null;
    if (crrntUser?.admin){
        resultRow = <Grid container spacing={1}>
            <Grid container item xs={3}>
                <TextField id="home" type="number" label="Thuis" variant="outlined" value={homeGoals} onChange={event => setHomegoals(event.target.value)}/>
            </Grid>
            <Grid container item xs={3}>
                <TextField id="away" type="number" label="Uit" variant="outlined" value={awayGoals} onChange={event => setAwaygoals(event.target.value)}/>
            </Grid>
            <Grid container item xs={3}>
                <Button variant="contained" color="primary" onClick={() => dispatch(postResultAdmin([crrntUser?.token, question.id, homeGoals, awayGoals]))}>
                    Stuur
                </Button>
            </Grid>
        </Grid>
    }

    return (
            <Box boxShadow={3} borderRadius={10} style={{marginTop: "1em"}}>
                <Alert icon={false} severity='info'>
                    <AlertTitle>{question.text}</AlertTitle>
                    {prettifyDate(question.deadline)}
                    <span style={{paddingLeft: "2.5em"}}>{question.subText}</span>
                </Alert>
                {resultRow}
                <List component="nav">
                    {question.answers.map((answer: Answer, index: number) => {

                        return <ListItem onClick={() => {
                                if (pending_questions.indexOf(question.id) < 0) {
                                    dispatch(postAnswer([crrntUser?.token, question.id, answer.id]))
                                }
                            }} style={getStyle(answer.selected, index === question.answers.length-1)} key={answer.id} selected={answer.selected} button>
                                <ListItemText primary={answer.text}
                                              secondary={answer.reward === null ? "Punten nog niet bekend" : "Punten: " + answer.reward}/>
                                {getIcon(pending_answers, answer)}
                            </ListItem>;
                        }
                    )}
                </List>
            </Box>
    );
}

function getStyle(selected: boolean | undefined, isLast: boolean): CSSProperties{
    const style: CSSProperties = {paddingTop: 0, paddingBottom: 0};

    if (selected){
        style.backgroundColor = "#99bbff";
    }
    if (isLast){
        style.borderBottomLeftRadius = "22px";
        style.borderBottomRightRadius = "22px";
    }

    return style;
}

function getIcon(pending_answers: number[], answer: Answer){
    if (pending_answers.indexOf(answer.id) > -1){
        return <CircularProgress/>;
    }
    if (answer.correct){
        return <CheckIcon
            htmlColor={'green'}
            fontSize={'large'}
        />
    }
    return null;
}

function prettifyDate(dateAsString: string){
    if (!dateAsString){
        return dateAsString;
    }

    let date: Date = new Date(dateAsString);
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return date.getDate() + "-" + (date.getMonth() + 1) + " " + date.getHours() + ":" + minutes;
}

export default PredictionQuestion;

