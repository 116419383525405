import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getBaseUrl} from "../Util";
import {AuthState, SignInUser, SignUpUser} from "../models/authState";

export const signIn = createAsyncThunk<any, SignInUser, any>(
    'auth/signIn',
    async (user, {rejectWithValue}) => {
        const response = await fetch(`${getBaseUrl()}/api/auth/signin`,{
            method: "POST",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `username=${user.username}&password=${user.password}`
        }).then(resp => resp.status === 200 ? resp.json() : rejectWithValue(resp.status))

        return response
    }
);

export const signUp = createAsyncThunk<any, SignUpUser, any>(
    'auth/signUp',
    async (user , {rejectWithValue})=> {
        const response: any = await fetch(`${getBaseUrl()}/api/auth/signup`,{
            method: "POST",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `username=${user.username}&email=${user.email}&password=${user.password}&recaptchaResponse=${user.recaptchaResponse}`
        }).then(resp => {
            return resp.status === 200 ? resp.json() : rejectWithValue(resp.status);
        })

        return response


    }
);

const currentUserStorageKey = 'currentUser';

function initialState(): AuthState {
    let state: AuthState = {
        signInState:{pending: false, severity: "info", message: ""},
        signUpState:{pending: false, severity: "info", message: ""}
    };

    if (sessionStorage.currentUser){
        try {
            state.currentUser = JSON.parse(sessionStorage.getItem(currentUserStorageKey) as string);
        } catch (error){
            console.log("parsing failed: " + error);
        }
    }

    return state;

}


export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState(),
    reducers: {
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(signIn.pending, (state: AuthState, action: any) => {
                state.signInState.pending = true;
            })
            .addCase(signIn.fulfilled, (state: AuthState, action: any) => {
                state.signInState.pending = false;
                if (typeof action.payload.token !== 'undefined'){
                    setCurrentUser(state, action.payload);
                    state.signInState.message = "";
                }
                 else {
                    state.signInState.severity = 'error';
                    state.signInState.message = "Er ging iets fout. Code: " + action.payload.status;
                }
            })
            .addCase(signIn.rejected, (state: AuthState, action: any) => {
                state.signInState.pending = false;
                if (action.payload === 401){
                    state.signInState.message = "Niemand bekend met deze gegevens"
                    state.signInState.severity = "warning";
                } else {
                    state.signInState.severity = 'error';
                    state.signInState.message = "Kan geen verbinding maken.";
                }
            })
            .addCase(signUp.pending, (state: AuthState, action: any) => {
                state.signUpState.pending = true;
            })
            .addCase(signUp.fulfilled, (state: AuthState, action: any) => {
                state.signUpState.pending = false;
                if (typeof action.payload.token !== 'undefined'){
                    state.signUpState.message = "";
                    setCurrentUser(state, action.payload);
                } else {
                    state.signUpState.severity = 'error';
                    state.signUpState.message = "Kan geen verbinding maken.";
                }
            })
            .addCase(signUp.rejected, (state: AuthState, action: any) => {
                state.signUpState.pending = false;
                if (action.payload === 460){
                    state.signUpState.message = "Deze gebruikersnaam bestaat al"
                    state.signUpState.severity = "warning";
                }
                else if (action.payload === 461){
                    state.signUpState.message = "Er bestaat al een account met dit e-mailadres"
                    state.signUpState.severity = "warning";
                }
                else if (action.payload === 462){
                    state.signUpState.message = "Gebruikersnaam is niet geldig"
                    state.signUpState.severity = "warning";
                }
                else if (action.payload === 463){
                    state.signUpState.message = "Wachtwoord is niet geldig"
                    state.signUpState.severity = "warning";
                } else if (action.payload === 468){
                    state.signUpState.message = "Het lijkt erop dat je een robot bent. Robots mogen niet meedoen."
                    state.signUpState.severity = "warning";
                }
                else {
                    state.signUpState.severity = 'error';
                    state.signUpState.message = "Er ging iets fout: " + action.payload;
                }

            })
            .addDefaultCase((state: AuthState, action: any) =>{
                if (action.payload && action.payload.status === 401){
                    state.signInState.message = "Je accountgegevens zijn niet geldig. Probeer opnieuw in te loggen."
                    state.signInState.severity = "warning";
                    sessionStorage.removeItem(currentUserStorageKey);
                    delete state.currentUser;
                }
            })
    }
});

function setCurrentUser(state: AuthState, payload: any){
    state.currentUser = {
        username: payload.username,
        token: payload.token,
        groupIdsOfUser: payload.groupIdsOfUser,
        roles: payload.roles,
        admin: payload.roles.indexOf("ROLE_ADMIN") > -1
    };
    sessionStorage.setItem(currentUserStorageKey, JSON.stringify(state.currentUser));
}



export const currentUser = (state: { auth: AuthState; }) => state.auth.currentUser;
export const currentUserToken = (state: { auth: AuthState; }) => state.auth.currentUser === undefined ? "" : state.auth.currentUser.token;
export const signUpState = (state: { auth: AuthState; }) => state.auth.signUpState;
export const signInState = (state: { auth: AuthState; }) => state.auth.signInState;
