import {configureStore} from '@reduxjs/toolkit';
import {authSlice} from './authSlice';
import {competitionSlice} from "./competitionSlice";
import {groupSlice} from "./groupSlice";
import {rankSlice} from "./rankSlice";
import {requestPasswordResetSlice} from "./requestPasswordResetSlice";
import {passwordResetSlice} from "./passwordResetSlice";
import {overviewSlice} from "./overviewSlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    competition: competitionSlice.reducer,
    group: groupSlice.reducer,
    rank: rankSlice.reducer,
    requestPasswordReset: requestPasswordResetSlice.reducer,
    passwordReset: passwordResetSlice.reducer,
    overview: overviewSlice.reducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});

