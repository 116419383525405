import React, {CSSProperties} from 'react';

import {Link} from "react-router-dom";
import {emailPattern} from "../Util";
import {useDispatch, useSelector} from "react-redux";
import {signUp, signUpState} from "../redux/authSlice";
import ReCAPTCHA from "react-google-recaptcha";

import {
    Alert,
    Avatar, Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const paper: CSSProperties = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const avatar: CSSProperties = {
    margin: 1
};
const form: CSSProperties = {
    width: '100%', // Fix IE 11 issue.
    marginTop: 3,
};
const submit: CSSProperties = {
    margin: 2
};

export default function SignUpPage() {
    const dispatch = useDispatch<any>();
    const connectionState = useSelector(signUpState);
    const [username, setUsername] = React.useState("");
    const [recaptchaResponse, setRecaptchaResponse] = React.useState<string | null>(null);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordRepeat, setPasswordRepeat] = React.useState("");

    const loader  = connectionState.pending ? <CircularProgress/> : null;
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;


    const usernameError = username.length === 0;
    const emailError = !RegExp(emailPattern).test(email);
    const passwordError = password.length < 8;
    const passwordRepeatError = password !== passwordRepeat;
    const recaptchaError = recaptchaResponse === null;
    const hasError = usernameError || emailError || passwordError || passwordRepeatError || recaptchaError;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={paper}>
                <Avatar style={avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Registreren
                </Typography>
                <form style={form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={username}
                                onChange={val => setUsername(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        dispatch(signUp({username: username, email: email, password: password, recaptchaResponse: recaptchaResponse}));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={usernameError}
                                id="username"
                                label="Gebruikersnaam"
                                name="username"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                onChange={val => setEmail(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        dispatch(signUp({username: username, email: email, password: password, recaptchaResponse: recaptchaResponse}));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={emailError}
                                id="email"
                                label="E-mailadres"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={password}
                                onChange={val => setPassword(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        dispatch(signUp({username: username, email: email, password: password, recaptchaResponse: recaptchaResponse}));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={passwordError}
                                name="password"
                                label="Wachtwoord (minstens 8 tekens)"
                                type="password"
                                id="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={passwordRepeat}
                                onChange={val => setPasswordRepeat(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        dispatch(signUp({username: username, email: email, password: password, recaptchaResponse: recaptchaResponse}));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={passwordRepeatError}
                                name="passwordRepeat"
                                label="Herhaal wachtwoord"
                                type="password"
                                id="passwordRepeat"
                            />
                        </Grid>
                    </Grid>
                    <div style={{marginTop: "1em"}}>
                        <div className="text-left" style={{fontWeight: "bold"}}>Robots mogen niet meedoen.</div>
                        <ReCAPTCHA
                            sitekey="6LckPdcZAAAAAHE8CCB2iVD4yKWSBQpdtrNRKN3r"
                            onChange={(response: string | null) => {setRecaptchaResponse(response)}} />
                    </div>
                    <Button
                        disabled={hasError || connectionState.pending}
                        onClick={() => dispatch(signUp({username: username, email: email, password: password, recaptchaResponse: recaptchaResponse}))}
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={submit}
                    >
                        Registreer
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to={"/"}>
                                Al een account? Meld je hier aan
                            </Link>
                        </Grid>
                        <Grid item>
                            <a target="_blank" rel="noopener noreferrer" href="/files/privacyverklaring.pdf" style={{float: 'right'}}>Privacyverklaring</a>
                        </Grid>
                    </Grid>
                    {loader}
                    {alert}
                </form>
            </div>
        </Container>
    );
}
