import React, {CSSProperties} from 'react';

import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {resetPassword, resetPasswordFetchState} from "../redux/passwordResetSlice";
import {
    Alert,
    Avatar, Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const paper: CSSProperties = {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };
const avatar: CSSProperties = {
        margin: 1
    };
const form: CSSProperties = {
        width: '100%', // Fix IE 11 issue.
        marginTop: 3,
    };
const submit: CSSProperties = {
        margin: 2
    };

export default function ResetPasswordPage() {
    const dispatch = useDispatch<any>();
    const connectionState = useSelector(resetPasswordFetchState);

    const [password, setPassword] = React.useState("");
    const [passwordRepeat, setPasswordRepeat] = React.useState("");

    const loader  = connectionState.pending ? <CircularProgress/> : null;
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    const passwordError = password.length < 8;
    const passwordRepeatError = password !== passwordRepeat;
    const hasError = passwordError || passwordRepeatError;


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={paper}>
                <Avatar style={avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Wachtwoord opnieuw instellen
                </Typography>
                <form style={form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={password}
                                onChange={val => setPassword(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        const token = new URL(window.location.href).searchParams.get("token");
                                        dispatch(resetPassword([token, password]));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={passwordError}
                                name="password"
                                label="Wachtwoord (minstens 8 tekens)"
                                type="password"
                                id="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={passwordRepeat}
                                onChange={val => setPasswordRepeat(val.target.value)}
                                onKeyUp={(val) => {
                                    if (val.key === 'Enter' && !hasError && !connectionState.pending){
                                        const token = new URL(window.location.href).searchParams.get("token");
                                        dispatch(resetPassword([token, password]));
                                    }
                                }}
                                variant="outlined"
                                required
                                fullWidth
                                error={passwordRepeatError}
                                name="passwordRepeat"
                                label="Herhaal wachtwoord"
                                type="password"
                                id="passwordRepeat"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={hasError || connectionState.pending}
                        onClick={() => {
                            const token = new URL(window.location.href).searchParams.get("token");
                            dispatch(resetPassword([token, password]));
                        }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={submit}
                    >
                        Stel wachtwoord opnieuw in
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link to={"/"}>
                                Toch geen nieuw wachtwoord? Meld je hier aan
                            </Link>
                        </Grid>
                    </Grid>
                    {loader}
                    {alert}
                </form>
            </div>
        </Container>
    );
}
