import React, {useEffect} from 'react';
import {
    Alert,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchRound,
    nOfAnsweredQuestionsSelector,
    pendingAnswers,
    predictionFetchState,
    questionsInRound,
    recomputeScoresForRound,
    selectedRoundForPredictionSelector,
    selectRoundForPrediction
} from "../redux/competitionSlice";
import {IdWithName} from "../models/idWithName";
import {currentUser} from "../redux/authSlice";
import {Question} from "../models/competition";
import PredictionQuestion from "./PredictionQuestion";
import {roundsForCompetition} from "../redux/overviewSlice";
import {useWindowSize} from "../Util";
import {SAVE_SUCCESS, smallScreenBound} from "../Constants";


function Prediction(){
    const dispatch = useDispatch<any>();
    const size = useWindowSize();
    const connectionState = useSelector(predictionFetchState);
    const crrntUser = useSelector(currentUser);
    const selectedRound = useSelector(selectedRoundForPredictionSelector);
    const [snackbarOpen, setSnackbarOpen] = React.useState(0);
    const nof_pending_answers = useSelector(pendingAnswers).length;

    let computeScoresButton = null;
    if (crrntUser?.admin){
        computeScoresButton = <Button variant="contained" color="primary" onClick={() => dispatch(recomputeScoresForRound([crrntUser?.token, selectedRound]))}>
            Update scores
        </Button>
    }

    const loadingOrReload  = connectionState.pending ? <CircularProgress/> : <IconButton disabled={selectedRound===''} onClick={() => dispatch(fetchRound([crrntUser?.token as string, selectedRound]))} color="primary" aria-label="Herlaad ronde">
        <RefreshIcon />
    </IconButton>;
    const alert = connectionState.pending || connectionState.message === "" || connectionState.message === SAVE_SUCCESS ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    const paddingTop = alert === null ? '7em' : '11em';

    useEffect(() => {

        if (nof_pending_answers > 0){
            setSnackbarOpen(1);
        } else if (snackbarOpen === 1 && connectionState.message === SAVE_SUCCESS){
            setSnackbarOpen(2);
        }
    }, [connectionState.message, nof_pending_answers, snackbarOpen]);


    const roundsForComp = useSelector(roundsForCompetition);
    const questions = useSelector(questionsInRound);
    const nOfAnsweredQuestions = useSelector(nOfAnsweredQuestionsSelector);

    const selectRoundHint = selectedRound === '' && roundsForComp.length > 0 ? <Alert severity='info'>Kies een speelronde</Alert> : null;

    let questionContainerStyle = size.width < smallScreenBound ? {paddingTop: paddingTop, paddingLeft: 0, paddingRight: 0} : {paddingTop: paddingTop};

    return (
        <div>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center' }} open={snackbarOpen === 2} autoHideDuration={2000} onClose={() => setSnackbarOpen(0)}>
                <Alert severity="success" elevation={6} variant="filled">
                     Voorspelling opgeslagen
                </Alert>
            </Snackbar>
            <div style={{position: 'fixed', width: '100%', zIndex: 99, backgroundColor: "white", top: 0, left: 0, paddingTop: '4em', paddingBottom: "1em"}}>
                <Container maxWidth="md">
                    <Grid container>
                        <Grid item md={3} sm={4} xs={false}/>
                        <Grid item md={7} sm={6} xs={9}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel id="roundSelectLabel">Ronde</InputLabel>
                                <Select
                                    labelId="roundSelectLabel"
                                    id="RoundSelect"
                                    value={selectedRound}

                                    onChange={(event: any, child) => {
                                        let selectedRoundId = event.target.value;
                                        dispatch(selectRoundForPrediction(selectedRoundId));
                                        dispatch(fetchRound([crrntUser?.token, selectedRoundId]));
                                    }}
                                    label="Ronde"
                                >
                                    {roundsForComp.map((idWithName: IdWithName) => <MenuItem key={idWithName.id} value={idWithName.id}>{idWithName.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {alert}
                            {selectRoundHint}
                        </Grid>
                        <Grid item style={{paddingLeft: '1em'}} xs={1}>
                            <div className = {connectionState.pending || selectedRound === '' ? "hidden" : ""}>
                                <p style={{marginTop: '0.4em', marginBottom: '0'}}>{nOfAnsweredQuestions}</p>
                                <p style={{marginTop: '0.2em', marginBottom: '0'}}>{questions.length}</p>
                            </div>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            {loadingOrReload}
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container style={questionContainerStyle} maxWidth="md">
                {questions.map((question: Question) => <PredictionQuestion key={question.id} id={question.id} text={question.text} subText={question.subText} deadline={question.deadline} answers={question.answers} />) }
                {computeScoresButton}
            </Container>

        </div>
    );
}

export default Prediction;

