import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getBaseUrl} from "../Util";
import {FetchState} from "../models/fetchState";

export const resetPassword = createAsyncThunk<any, any[], any>(
    'resetPassword/resetPass',
    async tokenAndNewPassword => {
        const response = await fetch(`${getBaseUrl()}/api/auth/resetPass`,{
            method: "POST",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: `resetPassToken=${tokenAndNewPassword[0]}&newPassword=${tokenAndNewPassword[1]}`
        }).then(resp => resp.status)

        return response
    }
);

const initialState: FetchState = {
    pending: false, severity: "info", message: ""
}


export const passwordResetSlice = createSlice({
    name: 'passwordReset',
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(resetPassword.pending, (state: FetchState, action: any) => {
                state.message = "";
                state.pending = true;
            })
            .addCase(resetPassword.fulfilled, (state: FetchState, action: any) => {
                state.pending = false;
                if (action.payload === 200) {
                    state.severity = "success";
                } else if (action.payload === 463) {
                    state.message = "Wachtwoord is niet geldig"
                    state.severity = "warning";
                } else if (action.payload === 470) {
                    state.message = "Deze wachtwoordherstellink klopt niet. Vraag een nieuwe aan."
                    state.severity = "warning";
                } else if (action.payload === 471) {
                    state.message = "Een wachtwoordherstellink is maar 10 minuten geldig. Vraag een nieuwe aan."
                    state.severity = "warning";
                }
                else {
                    state.severity = 'error';
                    state.message = "Er ging iets fout: " + action.payload;
                }
            })
            .addCase(resetPassword.rejected, (state: FetchState, action: any) => {
                state.pending = false;
                state.severity = 'error';
                state.message = "Kan geen verbinding maken.";
            })

    }
});

export const resetPasswordFetchState = (state: { passwordReset: FetchState; }) => state.passwordReset;

