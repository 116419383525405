import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import LogInPage from "./LogInPage";
import SignUpPage from "./SignUpPage";
import RequestPasswordReset from "./RequestPasswordReset";
import {useSelector} from "react-redux";
import {resetPasswordFetchState} from "../redux/passwordResetSlice";
import ResetPasswordPage from "./ResetPasswordPage";
import News from "./News";

function NotLoggedIdApp() {

    return (
          <Routes>
              <Route path="/signup" element={<SignUpPage />}/>
              <Route path="/forgotpassword" element={<RequestPasswordReset />}/>
              <Route path="/resetPass" element={useSelector(resetPasswordFetchState).severity === "success" ? <Navigate to="/" /> : <ResetPasswordPage />}/>
              <Route path="/signin" element={<LogInPage />}/>
              <Route path="/" element={
                  <div>
                      < LogInPage />
                      <News />
                  </div>
              }/>
          </Routes>

  );
}

export default NotLoggedIdApp;
