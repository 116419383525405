import React, {CSSProperties} from 'react';

import {signIn, signInState} from "../redux/authSlice";
import {Link} from "react-router-dom";
import {Alert, Button, CircularProgress, Container, Grid, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordFetchState} from "../redux/passwordResetSlice";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const paper: CSSProperties = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const avatar: CSSProperties = {
    margin: 1
};
const form: CSSProperties = {
    width: '100%', // Fix IE 11 issue.
    marginTop: 3,
};
const submit: CSSProperties = {
    margin: 2
};

export default function LogInPage() {
    const dispatch = useDispatch<any>();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const connectionState = useSelector(signInState);
    const resetPasswordConnectionState = useSelector(resetPasswordFetchState);

    const resetPassSuccessAlert = resetPasswordConnectionState.severity === "success" ? <Alert severity="success">Gelukt! Je kunt je nu aanmelden met je nieuwe wachtwoord.</Alert> : null;

    const loader  = connectionState.pending ? <CircularProgress/> : null;
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    return (
        <Container component="main" maxWidth="xs">

            <div style={paper}>

                <img alt="logo" src="/images/bb_logo.png" style={avatar}/>
                {resetPassSuccessAlert}
                <form style={form} noValidate>
                    <TextField
                        value={username}
                        onChange={(val: any) => setUsername(val.target.value)}
                        onKeyUp={(val) => {
                            if (val.key === 'Enter' && !connectionState.pending){
                                dispatch(signIn({username: username, password: password}));
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Gebruikersnaam"
                        name="username"
                        autoFocus
                    />
                    <TextField
                        value={password}
                        onChange={(val: any) => setPassword(val.target.value)}
                        onKeyUp={(val) => {
                            if (val.key === 'Enter' && !connectionState.pending){
                                dispatch(signIn({username: username, password: password}));
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Wachtwoord"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        disabled={connectionState.pending}
                        onClick={() => dispatch(signIn({username: username, password: password}))}
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={submit}
                    >
                       Aanmelden
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to={"/forgotpassword"} >
                                Wachtwoord vergeten?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to={"/signup"}>
                                Nog geen account?
                            </Link>
                        </Grid>
                    </Grid>
                    {loader}
                    {alert}
                </form>
            </div>
            <Grid container>
                <Grid item xs={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/BillieballieC"><TwitterIcon style={{color: '#1DA1F2'}}/></a>
                </Grid>
                <Grid item xs={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BillieballieC/"><FacebookIcon style={{color: '#3b5998'}}/></a>
                </Grid>
                <Grid item xs={1}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/billieballie_c/"><InstagramIcon style={{color: '#C13584'}}/></a>
                </Grid>
                <Grid item xs={1}>
                    <EmailIcon onClick={e => window.location.href = "mailto:billie@billieballie.com"} style={{color: '#A0522D', cursor: 'pointer'}}/>
                </Grid>
                <Grid item xs={8}>
                    <a target="_blank" rel="noopener noreferrer" href="/files/privacyverklaring.pdf" style={{float: 'right'}}>Privacyverklaring</a>
                </Grid>
            </Grid>

        </Container>
    );
}
