import React from 'react';
import {useSelector} from "react-redux";
import {currentUser} from "../redux/authSlice";
import NotLoggedIdApp from "./NotLoggedInApp";
import MainScreen from "./MainScreen";
import {getWPSearch} from "../Util";
import {useLocation} from "react-router-dom";
import News from "./News";
import {Container, Link, Alert} from "@mui/material";

function App() {
    const user = useSelector(currentUser);
    if (getWPSearch(useLocation())){
        return  <div>
            <div style={{position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100, top: 0, left: 0}}>
                <Container component="main" maxWidth="sm"><Alert severity='info'>
                    <span>Dit is een uitgelicht nieuwsbericht. Klik </span><Link href="/">hier</Link><span> om terug te gaan naar het spel.</span>
                </Alert></Container>
            </div>
            <div style={{marginTop: "2.5em"}}>
                <News />
            </div>
        </div>;
    }

    if (typeof user === 'undefined') {
        return <NotLoggedIdApp/>;
    }

    return <MainScreen />;
}

export default App;
