import {useEffect, useState} from "react";
import * as H from "history";

export function getBaseUrl() {
    let stage = process.env.REACT_APP_STAGE;
    if (stage === "PROD"){
        return "https://billieballie.com";
    } else if (stage === "STAGE"){
        return "http://localhost:81";
    } else {
        return "http://macbook-pro.local:8089";
    }
}

export function getWPBaseUrl() {
    let stage = process.env.REACT_APP_STAGE;
    if (stage === "PROD"){
        return `${window.location.origin}/wp`;
    } else if (stage === "STAGE"){
        return "http://localhost:81/wp/";
    } else {
        return "http://localhost:3000/wp_mock.html";
    }
}

export const emailPattern = "^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$";

export function getWPSearch(location: H.Location){
    const search = location.search;
    return search && (/^\?p=\d+$/.test(search) || /^\?s=/.test(search)) ? search : null;
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function getCompetitionId(): number{
    if (window.location) {
        const splittedPathName: string[] = window.location.pathname.split("/");
        if (splittedPathName.length === 3 && /[0-9]+/.test(splittedPathName[1])) {
            return parseInt(splittedPathName[1]);
        }
    }
    return -1;
}

