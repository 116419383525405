import React, {CSSProperties, useRef} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import {requestPasswordResetFetchState, requestPasswordResetToken} from "../redux/requestPasswordResetSlice";
import {
    Alert,
    Avatar, Button,
    CircularProgress,
    Container,
    CssBaseline,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const paper: CSSProperties = {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
};
const avatar: CSSProperties = {
    margin: 1
};
const formStyle: CSSProperties = {
    width: '100%', // Fix IE 11 issue.
    marginTop: 3,
};
const submit: CSSProperties = {
    margin: 2
};

export default function RequestPasswordReset() {
    const dispatch = useDispatch<any>();
    const captcha = useRef<ReCAPTCHA>(null);
    const [username, setUsername] = React.useState("");
    const [recaptchaResponse, setRecaptchaResponse] = React.useState<string | null>(null);
    const connectionState = useSelector(requestPasswordResetFetchState);

    const loader  = connectionState.pending ? <CircularProgress/> : null;
    const alert = connectionState.pending || connectionState.message === "" ? null : <Alert severity={connectionState.severity}>{connectionState.message}</Alert>;

    let form = <form style={formStyle} noValidate>
        <TextField
            value={username}
            onChange={(val: any) => setUsername(val.target.value)}
            onKeyUp={(val) => {
                if (val.key === 'Enter' && !connectionState.pending && recaptchaResponse != null){
                    dispatch(requestPasswordResetToken([username, recaptchaResponse]));
                }
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Gebruikersnaam"
            name="username"
            autoFocus
        />
        <div style={{marginTop: "1em"}}>
            <div className="text-left" style={{fontWeight: "bold"}}>Robots mogen geen wachtwoorden opnieuw instellen.</div>
            <ReCAPTCHA
                ref={captcha}
                sitekey="6LckPdcZAAAAAHE8CCB2iVD4yKWSBQpdtrNRKN3r"
                onChange={(response: string | null) => {setRecaptchaResponse(response)}} />
        </div>
        <Button
            disabled={connectionState.pending || recaptchaResponse == null}
            onClick={() => {
                dispatch(requestPasswordResetToken([username, recaptchaResponse]));
                if (captcha.current != null){
                    captcha.current.reset();
                }
            }}
            fullWidth
            variant="contained"
            color="primary"
            style={submit}
        >
            Stuur een wachtwoordherstel e-mail
        </Button>
        <Grid container>
            <Grid item xs>
                <Link to={"/"}>
                    Meld je hier aan
                </Link>
            </Grid>
            <Grid item>
                <Link to={"/signup"}>
                    Nog geen account?
                </Link>
            </Grid>
        </Grid>
        {loader}
        {alert}
    </form>

    return (
        <Container component="main" maxWidth="xs">

            <CssBaseline />
            <div style={paper}>

                <Avatar style={avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Wachtwoord vergeten
                </Typography>
                {connectionState.severity === 'success' ? alert : form}
            </div>
        </Container>
    );
}
