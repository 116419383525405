import React from 'react';
import './index.css';
import App from './components/App';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from "./redux/store";
import {BrowserRouter as Router} from 'react-router-dom';
import {createRoot} from "react-dom/client";

let container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Provider store={store}>
    <Router>
        <App/>
    </Router>
</Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
