import React, {CSSProperties, useEffect} from 'react';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {overviewFetchStateSelector, userCompetitionsSelector} from "../redux/overviewSlice";
import LoggedIdApp from "./LoggedInApp";
import {CircularProgress, Container, Grid, IconButton, List, ListItem, ListItemText, Alert} from "@mui/material";
import {getCompetitionId, useWindowSize} from "../Util";
import {smallScreenBound} from "../Constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import News from "./News";

const validPathRegex = RegExp(/^\/([0-9]+\/(prediction|rank|groups))|news$/);

function MainScreen(){
    const location = useLocation();
    const navigate = useNavigate();
    const size = useWindowSize();
    const [currentPath, setCurrentPath] = React.useState(location.pathname);
    const connectionState = useSelector(overviewFetchStateSelector);
    let userCompetitions = useSelector(userCompetitionsSelector);
    let connectionIndicator = null;
    if (connectionState.pending){
        connectionIndicator = <CircularProgress/>;
    } else if(connectionState.message !== ""){
        connectionIndicator = <Alert icon={false} severity={connectionState.severity}>{connectionState.message}</Alert>;
    }

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    let changePath = (newPath: string) => {
        navigate(newPath);
        setCurrentPath(newPath);
    }

    let leftSideStyle: CSSProperties = size.width < smallScreenBound ? {zIndex: 101} : {position: 'fixed', zIndex: 101};

    let leftSide: JSX.Element | null = <Grid item md={2} sm={3} xs={12}>
        <div style={leftSideStyle}>
            <div style={{minHeight: '3em', marginLeft: '1em', marginRight: '1em'}}>
                {connectionIndicator}
            </div>
            <List component="nav" aria-label="competitionSelection">
                <ListItem selected={getCompetitionId() === -1} key={"news"} button divider onClick={() => changePath("/news")}>
                    <ListItemText primary={"Nieuws"} />
                </ListItem>
                {userCompetitions.map(usercomp =>
                    <ListItem selected={usercomp.competitionId === getCompetitionId()} key={usercomp.competitionId} button divider onClick={() => changePath("/" + usercomp.competitionId + "/prediction")}>
                        <ListItemText primary={usercomp.competitionName} />
                    </ListItem>)}
            </List>
        </div>
    </Grid>;
    let rightSideCandidate;

    if (validPathRegex.test(currentPath)){
        rightSideCandidate = getCompetitionId() === -1 ? <News/> : <LoggedIdApp/>
        if(size.width < smallScreenBound){
            leftSide = <div style={{position: 'fixed', zIndex: 101, top: 5, left: 5}}>
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton aria-label="back" color="primary" onClick={() => changePath("/")}>
                            <ArrowBackIosIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>;
        }
    } else {
        rightSideCandidate = size.width < smallScreenBound ? null : <News/>;
    }

    let rightSide = rightSideCandidate === null ? null : <Grid className={"box"} item md={10} sm={9} xs={12}>{rightSideCandidate}</Grid>;

    return <Container maxWidth="md">
        <Grid container spacing={2} className={"wrap"}>
            {leftSide}
            {rightSide}
        </Grid>
    </Container>
}

export default MainScreen;

