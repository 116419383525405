import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getBaseUrl} from "../Util";
import {FetchState} from "../models/fetchState";
import {GroupState} from "../models/groupState";
import {Group} from "../models/group";

export const fetchUserGroups = createAsyncThunk<any, any[], any>(
    'groups/groupsOfUser',
    async tokenAndCompetitionId => {
        const response = await fetch(`${getBaseUrl()}/api/game/groups/getGroupsOfUser?competitionId=${tokenAndCompetitionId[1]}`,{
            method: "GET",
            headers: {'Authorization':'Bearer ' + tokenAndCompetitionId[0]},
            credentials: "include"
        }).then(resp => resp.json())

        return response
    }
);

export const createGroup = createAsyncThunk<any, any[], any>(
    'groups/createGroup',
    async tokenAndGroupNameAndCompetitionId => {
        const response = await fetch(`${getBaseUrl()}/api/game/groups/createGroup`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenAndGroupNameAndCompetitionId[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `name=${tokenAndGroupNameAndCompetitionId[1]}&competitionId=${tokenAndGroupNameAndCompetitionId[2]}`
        }).then(resp => resp.json())

        return response
    }
);

export const joinGroup = createAsyncThunk<any, any[], any>(
    'groups/joinGroup',
    async (tokenAndGroupCodeAndCompetitionId, {rejectWithValue}) => {
        const response = await fetch(`${getBaseUrl()}/api/game/groups/joinGroup`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenAndGroupCodeAndCompetitionId[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `code=${tokenAndGroupCodeAndCompetitionId[1]}&competitionId=${tokenAndGroupCodeAndCompetitionId[2]}`
        }).then(resp => {
            return resp.status === 200 ? resp.json() : rejectWithValue(resp.status);
        })

        return response
    }
);

export const leaveGroup = createAsyncThunk<any, any[], any>(
    'groups/leaveGroup',
    async tokenAndGroupIdAndCompetitionId => {
        const response = await fetch(`${getBaseUrl()}/api/game/groups/leaveGroup`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenAndGroupIdAndCompetitionId[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `groupId=${tokenAndGroupIdAndCompetitionId[1]}&competitionId=${tokenAndGroupIdAndCompetitionId[2]}`
        }).then(resp => resp.json())

        return response
    }
);

const initialState: GroupState = {
    groupsOfUser: [],
    fetchState: {pending: false, severity: "info", message: ""}
}

export const groupSlice = createSlice({
    name: 'group',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchUserGroups.pending, setPending)
            .addCase(fetchUserGroups.fulfilled, setFullfilledWithUsergroupsResult)
            .addCase(fetchUserGroups.rejected, setRejected)
            .addCase(createGroup.pending, setPending)
            .addCase(createGroup.fulfilled, (state: GroupState, action: any) => {
                setFullfilledWithUsergroupsResult(state, action);
                if (!action.payload.status) {
                    state.fetchState.severity = 'success';
                    state.fetchState.message = "Gelukt! De groep '" + action.meta.arg[1] + "' staat nu onderaan deze pagina bij 'Mijn groepen'. Anderen kunnen aansluiten als ze de code weten.";
                }
            })
            .addCase(createGroup.rejected, setRejected)
            .addCase(joinGroup.pending, setPending)
            .addCase(joinGroup.fulfilled, (state: GroupState, action: any) => {
                setFullfilledWithUsergroupsResult(state, action);
                if (!action.payload.status) {
                    state.fetchState.severity = 'success';
                    let groupName = state.groupsOfUser
                        .filter((group: Group) => group.code === action.meta.arg[1])
                        .map((group: Group) => group.name)[0];
                    state.fetchState.message = "Gelukt! Je bent nu lid van de groep '" + groupName + "'. Deze groep staat nu onderaan deze pagina bij 'Mijn groepen'.";
                }
            })
            .addCase(joinGroup.rejected, (state: GroupState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload === 466){
                    state.fetchState.message = "Er bestaat geen groep met deze code"
                    state.fetchState.severity = "warning";
                }
                else if (action.payload === 467){
                    state.fetchState.message = "Je bent al lid van deze groep"
                    state.fetchState.severity = "warning";
                }
                else {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout: " + action.payload;
                }
            })
            .addCase(leaveGroup.pending, setPending)
            .addCase(leaveGroup.fulfilled, (state: GroupState, action: any) => {
                setFullfilledWithUsergroupsResult(state, action);
                if (!action.payload.status) {
                    state.fetchState.severity = 'success';
                    state.fetchState.message = "Gelukt! Je bent geen lid meer van de groep '" + action.meta.arg[3] + "'.";
                }
            })
            .addCase(leaveGroup.rejected, setRejected)

    }
});

function setPending(state: GroupState, action: any){
    state.fetchState.message = "";
    state.fetchState.pending = true;
}

function setFullfilledWithUsergroupsResult(state: GroupState, action: any){
    state.fetchState.pending = false;
    if (action.payload.status) {
        state.fetchState.severity = 'error';
        state.fetchState.message = "Er ging iets fout. Code: " + action.payload.status;
    } else {
        state.groupsOfUser = action.payload.sort((group1: Group, group2: Group) => group1.name.localeCompare(group2.name));
        state.fetchState.message = "";
    }
}

function setRejected(state: GroupState, action: any) {
    state.fetchState.pending = false;
    state.fetchState.severity = 'error';
    state.fetchState.message = "Kan geen verbinding maken.";
}



export const groupsOfUser = (state: { group: { groupsOfUser: Group[]; }; }) => state.group.groupsOfUser;
export const groupFetchState = (state: { group: { fetchState: FetchState; }; }) => state.group.fetchState;
