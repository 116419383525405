import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CompetitionState} from "../models/competitionState";
import {getBaseUrl} from "../Util";
import {FetchState} from "../models/fetchState";
import {Question} from "../models/competition";
import {SAVE_SUCCESS} from "../Constants";
import {getCompetitionRounds} from "./overviewSlice";

export const fetchRound = createAsyncThunk<any, string[], any>(
    'competition/round',
    async tokenAndRoundId => {
        const response = await fetch(`${getBaseUrl()}/api/game/prediction/getPredictionsForRound?roundId=${tokenAndRoundId[1]}`,{
            method: "GET",
            headers: {'Authorization':'Bearer ' + tokenAndRoundId[0]},
            credentials: "include"
        }).then(resp => resp.json())

        return response
    }
);

export const postAnswer = createAsyncThunk<any, any[], any>(
    'competition/postAnswer',
    async tokenQuestionIdAnswerId => {
        const response = await fetch(`${getBaseUrl()}/api/game/prediction/postAnswer`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenQuestionIdAnswerId[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `questionId=${tokenQuestionIdAnswerId[1]}&answerId=${tokenQuestionIdAnswerId[2]}`
        }).then(resp => resp.status)

        return response
    }
);

export const postResultAdmin = createAsyncThunk<any, any[], any>(
    'competition/postResultAdmin',
    async tokenQuestionIdHomeGoalsAwayGoals => {
        const response = await fetch(`${getBaseUrl()}/api/admin/setMatchResult`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenQuestionIdHomeGoalsAwayGoals[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `questionId=${tokenQuestionIdHomeGoalsAwayGoals[1]}&homeGoals=${tokenQuestionIdHomeGoalsAwayGoals[2]}&awayGoals=${tokenQuestionIdHomeGoalsAwayGoals[3]}`
        }).then(resp => resp.status)

        return response
    }
);

export const recomputeScoresForRound = createAsyncThunk<any, any[], any>(
    'competition/recomputeScoresForRound',
    async tokenAndRoundId => {
        const response = await fetch(`${getBaseUrl()}/api/admin/recomputeScoresForRound`,{
            method: "POST",
            headers: {'Authorization':'Bearer ' + tokenAndRoundId[0], 'Content-Type':'application/x-www-form-urlencoded'},
            body: `roundId=${tokenAndRoundId[1]}`
        }).then(resp => resp.status)

        return response
    }
);

const initialState: CompetitionState = {
    selectedRound: '',
    currentRound: [],
    pendingQuestions: [],
    pendingAnswers: [],
    fetchState: {pending: false, severity: "info", message: ""}
};

export const competitionSlice = createSlice({
    name: 'competition',
    initialState: initialState,
    reducers: {
        selectRoundForPrediction: (state: CompetitionState, selectedRound: PayloadAction<string>) => {
            state.selectedRound = selectedRound.payload;
        },
        resetPredictionState: () => initialState
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchRound.pending, (state: CompetitionState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(fetchRound.fulfilled, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload.status) {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload.status;
                } else {
                    state.currentRound = getQuestionsFromPayload(action.payload);
                    state.fetchState.message = "";
                }
            })
            .addCase(fetchRound.rejected, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
            .addCase(getCompetitionRounds.fulfilled, (state: CompetitionState, action: any) => {
                if (!action.payload.status) {
                    state.currentRound = getQuestionsFromPayload(action.payload.questionsAndAnswers);
                    state.selectedRound = action.payload.currentRoundId === null ? "" : action.payload.currentRoundId;
                }
            })
            .addCase(postAnswer.pending, (state: CompetitionState, action: any) => {
                state.fetchState.message = "";
                state.pendingQuestions.push(action.meta.arg[1]);
                state.pendingAnswers.push(action.meta.arg[2]);
            })
            .addCase(postAnswer.fulfilled, (state: CompetitionState, action: any) => {
                updatePendingQuestionAnswer(state, action.meta.arg[1], action.meta.arg[2]);
                if (action.payload === 200) {
                    state.fetchState.message = "";
                    state.fetchState.severity = 'success';
                    state.fetchState.message = SAVE_SUCCESS;
                    mergeSelectedAnswer(state, action.meta.arg[1], action.meta.arg[2]);
                } else if (action.payload === 464) {
                    state.fetchState.severity = 'warning';
                    state.fetchState.message = 'Helaas, deze vraag is al gesloten.';
                } else {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload;
                }
            })
            .addCase(postAnswer.rejected, (state: CompetitionState, action: any) => {
                updatePendingQuestionAnswer(state, action.meta.arg[1], action.meta.arg[2]);
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
            .addCase(postResultAdmin.pending, (state: CompetitionState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(postResultAdmin.fulfilled, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload === 200) {
                    state.fetchState.severity = 'success';
                    state.fetchState.message = "Uitslag opgeslagen";
                } else {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload;
                }
            })
            .addCase(postResultAdmin.rejected, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
            .addCase(recomputeScoresForRound.pending, (state: CompetitionState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(recomputeScoresForRound.fulfilled, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload === 200) {
                    state.fetchState.severity = 'success';
                    state.fetchState.message = "Scores uitgerekend!";
                } else {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload;
                }
            })
            .addCase(recomputeScoresForRound.rejected, (state: CompetitionState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
    }
});

function mergeSelectedAnswer(state: CompetitionState, questionId: number, answerId: number) {
    state.currentRound.forEach(question => {
        if (question.id === questionId){
            question.answers.forEach(answer => answer.selected = answer.id === answerId);
        }
    })

}

function updatePendingQuestionAnswer(state: CompetitionState, questionId: number, answerId: number){
    let questionIndex = state.pendingQuestions.indexOf(questionId);
    if (questionIndex > -1){
        state.pendingQuestions.splice(questionIndex, 1);
    }

    let answerIndex = state.pendingAnswers.indexOf(answerId);
    if (answerIndex > -1){
        state.pendingAnswers.splice(answerIndex, 1);
    }
}

function getQuestionsFromPayload(questionAndAnswers: object[]): Question[]{
    let resultMap: any = {};
    questionAndAnswers.forEach((questionAndAnswer: any) =>{
        if (!resultMap[questionAndAnswer.questionId]){
            resultMap[questionAndAnswer.questionId] = {
                id: questionAndAnswer.questionId,
                text: questionAndAnswer.questionText,
                subText: questionAndAnswer.subText,
                deadline: questionAndAnswer.deadline,
                answers: []
            };
        }
        resultMap[questionAndAnswer.questionId].answers.push(
            {
                id: questionAndAnswer.answerId,
                text: questionAndAnswer.answerText,
                reward: questionAndAnswer.reward,
                correct: questionAndAnswer.correct,
                selected: questionAndAnswer.selected
            }
        )

    });
    let result: Question[] = Object.values(resultMap);
    result.forEach((question: any) => question.answers.sort((ans1: any, ans2: any) => ans1.id - ans2.id));
    result.sort((q1: any, q2: any) => q1.deadline.localeCompare(q2.deadline));
    return result;
}

export const {selectRoundForPrediction, resetPredictionState} = competitionSlice.actions;
export const selectedRoundForPredictionSelector = (state: { competition: { selectedRound: string; }; }) => state.competition.selectedRound;

export const questionsInRound = (state: { competition: { currentRound: Question[]; }; }) => state.competition.currentRound;
export const nOfAnsweredQuestionsSelector = (state: { competition: { currentRound: Question[]; }; }) => state.competition.currentRound.filter(question => question.answers.filter(answer => answer.selected).length > 0).length;
export const predictionFetchState = (state: { competition: { fetchState: FetchState; }; }) => state.competition.fetchState;
export const pendingQuestions = (state: { competition: { pendingQuestions: number[]; }; }) => state.competition.pendingQuestions;
export const pendingAnswers = (state: { competition: { pendingAnswers: number[]; }; }) => state.competition.pendingAnswers;
