import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {OverviewState} from "../models/overviewState";
import {UserCompetition} from "../models/userCompetition";
import {signIn, signUp} from "./authSlice";
import {getBaseUrl} from "../Util";
import {IdWithName} from "../models/idWithName";
import {FetchState} from "../models/fetchState";

export const getCompetitionRounds = createAsyncThunk<any, any[], any>(
    'competition/getCompetitionRounds',
    async tokenAndCompetitionId => {
        const response = await fetch(`${getBaseUrl()}/api/game/competition/getCompetitionRounds?competitionId=${tokenAndCompetitionId[1]}`,{
            method: "GET",
            headers: {'Authorization':'Bearer ' + tokenAndCompetitionId[0]},
            credentials: "include"
        }).then(resp => resp.json())

        return response
    }
);

const userCompetitionsStorageKey = 'userCompetitions';

function initialState(): OverviewState{
    let state: OverviewState = {
        userCompetitions: [],
        roundsForCompetition: [],
        fetchState: {pending: false, severity: "info", message: ""}
    };

    if (sessionStorage[userCompetitionsStorageKey]){
        try {
            state.userCompetitions = JSON.parse(sessionStorage.getItem(userCompetitionsStorageKey) as string);
        } catch (error){
            console.log("parsing failed: " + error);
        }
    }
    return state;
}

export const overviewSlice = createSlice({
    name: 'overview',
    initialState: initialState(),
    reducers: {}
    ,
    extraReducers: (builder: any) => {
        builder
            .addCase(getCompetitionRounds.pending, (state: OverviewState, action: any) => {
                state.fetchState.message = "";
                state.fetchState.pending = true;
            })
            .addCase(getCompetitionRounds.fulfilled, (state: OverviewState, action: any) => {
                state.fetchState.pending = false;
                if (action.payload.status) {
                    state.fetchState.severity = 'error';
                    state.fetchState.message = "Er ging iets fout. Code: " + action.payload.status;
                } else {
                    setRoundsForCompetition(state, action.payload.rounds);
                    state.fetchState.message = "";
                }
            })
            .addCase(getCompetitionRounds.rejected, (state: OverviewState, action: any) => {
                state.fetchState.pending = false;
                state.fetchState.severity = 'error';
                state.fetchState.message = "Kan geen verbinding maken.";
            })
            .addCase(signIn.fulfilled, (state: OverviewState, action: any) => {
                if (typeof action.payload.token !== 'undefined') {
                    setUserCompetitions(state, action.payload.userCompetitions);
                }
            })
            .addCase(signUp.fulfilled, (state: OverviewState, action: any) => {
                if (typeof action.payload.token !== 'undefined') {
                    setUserCompetitions(state, action.payload.userCompetitions);
                }
            })
    }
});

function setRoundsForCompetition(state: OverviewState, roundsForCompetition: IdWithName[]){
    state.roundsForCompetition = roundsForCompetition.sort((rnd1: IdWithName, rnd2: IdWithName) => rnd1.id - rnd2.id);
}

function setUserCompetitions(state: OverviewState, userCompetitions: UserCompetition[]){
    state.userCompetitions = userCompetitions.sort(compareUserCompetitions);
    sessionStorage.setItem(userCompetitionsStorageKey, JSON.stringify(state.userCompetitions));
}

function compareUserCompetitions(userComp1: UserCompetition, userComp2: UserCompetition){
    if (userComp1.userParticipates === userComp2.userParticipates){
        return userComp1.competitionName.localeCompare(userComp2.competitionName);
    }
    return userComp1.userParticipates ? -1 : 1;
}

export const userCompetitionsSelector = (state: { overview: { userCompetitions: UserCompetition[]; }; }) => state.overview.userCompetitions;
export const roundsForCompetition = (state: { overview: { roundsForCompetition: IdWithName[]; }; }) => state.overview.roundsForCompetition;
export const overviewFetchStateSelector = (state: { overview: { fetchState: FetchState; }; }) => state.overview.fetchState;
